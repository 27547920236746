import axios from "axios";
import authHeader from "./auth-header";

const BASE_URL = window.location.origin + "/api/";

class TokenService {
  getData(token) {
    const url = `token/${token}`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }

  getMobbeel() {
    const url = `token/mobbeel`;
    return axios.get(BASE_URL + url, { headers: authHeader() }).then((response) => {
      return response.data;
    });
  }
}

export default new TokenService();
