<template>
  <div>
    <b-form>
      <div class="container wizard">
        <div class="row">
          <div class="col-12 p-0">
            <h2>Documento a firmar</h2>
          </div>
        </div>
        <div class="row paginator">
          <div class="col-md-3 col-3">
            <router-link to="/paso-1.html" class="passed">1<i class="paso-1"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-2.html" class="passed">2<i class="paso-2"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-3.html" class="selected">3<i class="paso-3"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-4.html" class="">4<i class="paso-4"></i></router-link>
          </div>
        </div>
        <div class="row content">
          <div v-if="this.url === ''">
            <b-alert show variant="info">
              <i class="info-icon"></i>
              <div style="width: 100%">
                <p class="mb-0">Espera unos segundos mientras procesamos los datos...</p>
              </div>
            </b-alert>
          </div>
          <iframe
            v-if="this.url !== ''"
            :src="this.url"
            width="100%"
            height="450px"
            v-on:load="this.loadIFrame"
          ></iframe>
          <div v-if="errorMessage != ''" class="alert alert-danger" role="alert">
            <i class="alert-icon"></i>
            {{ this.errorMessage }}
          </div>
        </div>
        <div class="col-12 btn-container"></div>
      </div>
    </b-form>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
import * as levenshtein from "damerau-levenshtein";
import FormService from "../services/form.service";

export default {
  components: {},

  data() {
    return {
      url: "",
      errorMessage: "",
      form: {
        score: 0,
        state: "",
        token: "",
      },
    };
  },
  mounted() {
    const { scores } = this.$store.state.wizard.form.face.data;
    const { scanId } = this.$store.state.wizard.form.face.data;
    const { state } = this.$store.state.wizard.form.face.data;
    const { data } = this.$store.state.wizard.form.document;

    const { mobbeel } = this.$store.state.app;

    const { formData, token } = this.$store.state.app.token;

    if (token === undefined || token === null || token === "") {
      this.$router.push("/");
    }

    this.form.score = scores.faceMatching;
    this.form.state = state;
    this.form.token = token;

    console.debug(data, formData, mobbeel.score);

    if (
      data.document.validationFrontNotPhotocopy !== "VALID" ||
      data.document.validationBackNotPhotocopy !== "VALID"
    ) {
      console.debug("validationFrontNotPhotocopy", data.document.validationFrontNotPhotocopy);
      console.debug("validationBackNotPhotocopy", data.document.validationBackNotPhotocopy);

      this.$bvToast.toast(`Problemas de autenticidad del documento`, {
        title: "El documento no pudo ser validado",
        variant: "danger",
        solid: true,
      });

      this.$store.dispatch("wizard/reset");
      setTimeout(() => this.$router.push("/paso-2.html"), 5000);
    }

    if (!this.valid(this.form.score, mobbeel.score, formData, data)) {
      this.$store.dispatch("wizard/reset");
      setTimeout(() => this.$router.push("/paso-2.html"), 5000);
    } else {
      this.solicitarFirma(scanId, this.form.score);
    }

    if (screen.width < 600) window.scrollTo(0, 220);

    // this.solicitarFirma(scanId, score);
  },
  methods: {
    solicitarFirma(scanId, score) {
      this.$store.dispatch("app/loading", true);

      const data = {
        token: this.$store.state.app.token.token,
        documentFrontImage: this.$store.state.wizard.form.document.fromImage,
        documentBackImage: this.$store.state.wizard.form.document.backImage,
        faceImage: this.$store.state.wizard.form.face.image,
        scanId: scanId,
        score: score,
      };

      FormService.send(data).then((response) => {
        console.debug("Send response : ", response.data);
        this.getPdfInterval(response.data.eviID);
      });
    },
    getPdfInterval(uniqueId) {
      var $this = this;

      function requestIntervalFunction(uniqueId) {
        try {
          FormService.status({ uniqueId }).then((response) => {
            if (response.data.statusCode == "200") {
              console.debug("Searching Sign ...", response.data);
              var url = response.data.bulkSignUrl;
              var signIn = response.data.signIn;

              if (signIn == "True" || signIn == "False") {
                console.debug("Eureka Sign : ");
                $this.$store.dispatch("app/loading", false);
                $this.$store.dispatch("wizard/accepted", signIn == "True");
                clearInterval(requestIntervalId);
                $this.next();
              }

              if ($this.url == "") {
                console.debug("Eureka URL : ", url);
                $this.url = url;
              }
            } else {
              console.debug("Searching bulk ...", response.data);
            }
          });
        } catch {
          $this.$bvToast.toast(`Contactese con soporte de Evicertia`, {
            title: "Error al intentar recuperar el pdf",
            variant: "danger",
            solid: true,
          });
          $this.$store.dispatch("app/loading", false);
        }
      }

      var requestIntervalId = setInterval(requestIntervalFunction, 2000, uniqueId);
      setTimeout(() => {
        this.$store.dispatch("app/loading", false);
        this.$bvToast.toast(
          "No se ha podido obtener el PDF, por favor, contacte con Soporte. Su identificador es " +
            uniqueId,
          { title: "Error al intentar recuperar el pdf", variant: "danger", solid: true }
        );
        clearInterval(requestIntervalId);
      }, 600000);
    },
    loadIFrame(e) {
      if (e.target.src != "") {
        this.$store.dispatch("app/loading", false);
      }
    },
    next() {
      this.$router.push("/paso-4.html");
    },
    valid(score, minimumScore, formData, scanData) {
      try {
        const { firstName, lastName } = formData;
        let dataDocumentNumber = formData.documentNumber;

        const { name, surname, documentType } = scanData.document;
        let scanDocumentNumber = "";

        //TD1IDCard
        if (documentType === "PANIDCardV3") {
          scanDocumentNumber = scanData.document.personalNumber;
        } else {
          scanDocumentNumber = scanData.document.documentNumber;
        }

        //Data
        const dataFirstName = this.clearText(firstName, "!1");

        const dataLastName = this.clearText(lastName, "!2");

        dataDocumentNumber = this.clearText(dataDocumentNumber, "!3");

        //Scan
        const scanFirstName = this.clearText(name, "?1");

        const scanLastName = this.clearText(surname, "?2");

        scanDocumentNumber = this.clearText(scanDocumentNumber, "?3");

        //Validaciones
        const minimumScoreDecimal = minimumScore / 100;
        const validScore = score >= minimumScoreDecimal;

        const validData =
          this.comparateNames(dataFirstName, scanFirstName) ||
          this.comparateNames(dataLastName, scanLastName);

        const validDocumentNumber = dataDocumentNumber === scanDocumentNumber;

        console.debug(
          "Resultado score",
          validScore,
          "Valor devuelto",
          score,
          "Esperado score",
          minimumScoreDecimal
        );

        console.debug(
          "Resultado data name (Data->Scan)",
          validData,
          dataFirstName,
          scanFirstName,
          dataLastName,
          scanLastName
        );

        console.debug(
          "Resultado Document (Data->Scan)",
          validDocumentNumber,
          dataDocumentNumber,
          scanDocumentNumber
        );

        console.debug("Resultado document number ", validDocumentNumber);

        if (!validScore)
          this.errorMessage =
            "La validación facial no fue aprobada, por favor reintente nuevamente";

        if (!validData)
          this.errorMessage = `La validación de los datos personales no fue aprobada, por favor reintente nuevamente ${dataFirstName} ${dataLastName} <> ${scanFirstName} ${scanLastName}`;

        if (!validDocumentNumber)
          this.errorMessage = `La validación del número de documento no fue aprobada (${dataDocumentNumber}:${scanDocumentNumber}), por favor reintente nuevamente`;

        const valid = validScore && validData && validDocumentNumber;

        const messageSentry =
          "Token : " +
          this.form.token +
          "\n" +
          "---- Photocopy ----" +
          "\n" +
          "ValidationFrontNotPhotocopy : " +
          scanData.document.validationFrontNotPhotocopy +
          "\n" +
          "ValidationBackNotPhotocopy : " +
          scanData.document.validationBackNotPhotocopy +
          "\n" +
          " ---- Resultado Score ----" +
          "\n" +
          "Resultado score : " +
          validScore +
          "\n" +
          "Valor devuelto : " +
          score +
          "\n" +
          "Esperado score : " +
          minimumScoreDecimal +
          "\n" +
          " ---- Resultado data name (Data->Scan) ----" +
          "\n" +
          "Valido: " +
          validData +
          "\n" +
          "First Name : " +
          dataFirstName +
          "\n" +
          "Scan First Name : " +
          scanFirstName +
          "\n" +
          "last Name : " +
          dataLastName +
          "\n" +
          "Scan last Name : " +
          scanLastName +
          "\n" +
          " ---- Resultado Document (Data->Scan) ----" +
          "\n" +
          "validDocumentNumber: " +
          validDocumentNumber +
          "\n" +
          "dataDocumentNumber : " +
          dataDocumentNumber +
          "\n" +
          "scanDocumentNumber : " +
          scanDocumentNumber +
          "\n" +
          " ---- ErrorMessage ---- " +
          "\n" +
          this.errorMessage +
          "\n" +
          " ---- Result Final ---- " +
          "\n" +
          valid;

        Sentry.captureMessage(messageSentry);

        return valid;
      } catch (e) {
        console.debug(e);
        this.errorMessage = "La validación no fue aprobada, por favor reintente nuevamente";
        Sentry.captureException(e);
      }

      return false;
    },
    comparateNames(dataName, scanName) {
      const dataLength = dataName.split(" ").length;
      const scanLength = scanName.split(" ").length;

      let dataText = "?";
      let scanText = "!";

      if (dataLength === 1 && scanLength !== 1) {
        dataText = dataName.trim();
        scanText = scanName.split(" ")[0].trim();
      } else {
        dataText = dataName.trim();
        scanText = scanName.trim();
      }
      const lev = levenshtein(dataText, scanText);

      console.debug(
        "Levenshtein process : ",
        dataText,
        " with scan : ",
        scanText,
        " Result : ",
        lev
      );

      return lev.similarity > 0.7;
    },
    clearText(text, _default) {
      if (text !== undefined && text !== "") {
        return text.trim().toUpperCase().replaceAll("-", "");
      } else {
        return _default;
      }
    },
  },
};
</script>
