import Vue from "vue";
import VueRouter from "vue-router";
import Cancel01 from "../views/Cancel01.vue";
import Cancel02 from "../views/Cancel02.vue";
import Compare from "../views/Compare.vue";
import Home from "../views/Home.vue";
import Paso01 from "../views/Paso01.vue";
import Paso02 from "../views/Paso02.vue";
import Paso03 from "../views/Paso03.vue";
import Paso04 from "../views/Paso04.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/paso-4.html",
    name: "Paso 4",
    component: Paso04,
  },
  {
    path: "/paso-3.html",
    name: "Paso 3",
    component: Paso03,
  },
  {
    path: "/paso-2.html",
    name: "Paso 2",
    component: Paso02,
  },
  {
    path: "/paso-1.html",
    name: "Paso 1",
    component: Paso01,
  },
  {
    path: "/cancel-1.html",
    name: "Cancel 1",
    component: Cancel01,
  },
  {
    path: "/cancel-2.html",
    name: "Cancel 2",
    component: Cancel02,
  },
  {
    path: "/compare",
    name: "Compare",
    component: Compare,
  },
  {
    path: "/*",
    name: "Home",
    component: Home,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes,
});

export default router;
