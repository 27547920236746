<template>
  <div>
    <b-form @submit.stop.prevent="handleSubmit()">
      <div class="container wizard">
        <div class="row">
          <div class="col-12 p-0">
            <h2>Datos personales</h2>
          </div>
        </div>
        <div class="row paginator">
          <div class="col-md-3 col-3">
            <router-link to="/paso-1.html" class="selected">1<i class="paso-1"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-2.html" class="">2<i class="paso-2"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-3.html" class="">3<i class="paso-3"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-4.html" class="">4<i class="paso-4"></i></router-link>
          </div>
        </div>

        <div class="row content">
          <div class="col-12">
            <div v-if="cancelled" class="alert alert-success" role="alert">
              <i class="ok-icon"></i>
              Se ha realizado la cancelación del contrato de forma exitosa. Se le enviará por correo
              una copia del mismo.
            </div>
            <div v-if="!cancelled" class="alert alert-danger" role="alert">
              <i class="alert-icon"></i>
              No se pudo realizar la cancelación del contrato. Por favor en caso de que necesite una
              revisión, contáctese con el gestor.
            </div>
          </div>
        </div>
        <Redirect />
      </div>
    </b-form>
  </div>
</template>

<script>
import Redirect from "../components/layout/Redirect.vue";

export default {
  components: { Redirect },
  computed: {
    cancelled() {
      return this.$store.state.wizard.cancelled;
    },
  },
};
</script>
