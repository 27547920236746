<template>
  <div class="col-12"></div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      segundos: 5,
      urlRedirect: "",
    };
  },
  mounted() {
    this.urlRedirect = this.$store.state.app.token.formData.signedRedirectUrl;
    const $this = this;

    var redirectInternal = setInterval(redirectTime, 1000);

    function redirectTime() {
      $this.segundos -= 1;
    }
    setTimeout(() => {
      clearInterval(redirectInternal);
      window.location.href = $this.urlRedirect;
    }, this.segundos * 1000);
  },
};
</script>
