//import { face, documentFront, documentBack } from "./fakeImages";

const state = JSON.parse(localStorage.getItem("wizard"));

const initialState = state
  ? state
  : {
      index: 0,
      form: {
        document: {
          fromImage: undefined,
          backImage: undefined,
          data: { name: "", surname: "" },
        },
        face: { image: undefined, data: { state: "NONE", scores: {} } },
      },
      accepted: false,
      cancelled: false,
    };

export const wizard = {
  namespaced: true,
  state: initialState,
  actions: {
    save({ commit }, wizard) {
      commit("setWizard", wizard);
      return Promise.resolve(wizard);
    },
    documentFront({ commit }, image) {
      commit("setFromImage", image);
      return Promise.resolve(wizard);
    },
    documentBack({ commit }, image) {
      commit("setBackImage", image);
      return Promise.resolve(wizard);
    },
    documentData({ commit }, data) {
      commit("setDocumentData", data);
      return Promise.resolve(wizard);
    },
    face({ commit }, data) {
      commit("setFace", data);
      return Promise.resolve(wizard);
    },
    accepted({ commit }, accepted) {
      commit("setAccepted", accepted);
      return Promise.resolve(accepted);
    },
    cancelled({ commit }, cancelled) {
      commit("setCancelled", cancelled);
      return Promise.resolve(cancelled);
    },
    reset({ commit }) {
      commit("setReset");
      return Promise.resolve();
    },
  },
  mutations: {
    setWizard(state, wizard) {
      state.index = wizard.index;
      state.form = wizard.form;
    },
    setFromImage(state, image) {
      state.form.document.fromImage = image;
    },
    setBackImage(state, image) {
      state.form.document.backImage = image;
    },
    setDocumentData(state, data) {
      state.form.document.data = data;
    },
    setFace(state, data) {
      state.form.face.image = data.image;
      state.form.face.data = data.data;
    },
    setAccepted(state, accepted) {
      state.accepted = accepted;
    },
    setCancelled(state, cancelled) {
      state.cancelled = cancelled;
    },
    setReset(state) {
      state.form.document.fromImage = undefined;
      state.form.document.backImage = undefined;
      state.form.document.data = { name: "", surname: "" };
      state.form.face.image = undefined;
      state.form.face.data = {
        scanId: "",
        state: "NONE",
        scores: {
          faceMatching: 0,
          identityVerification: 0,
          liveness: 0,
        },
      };
    },
  },
};
