<template>
  <!-- WIZARD STEP # 5 -->
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Comparación de nombre</h2>
        <div class="col-12">
          <div class="col-3">Text 1</div>
          <div class="col-3">
            <input id="text1" v-model="text1" v-on:keyup="calcular()" />
          </div>
        </div>
        <div class="col-12">
          <div class="col-3">Text 2</div>
          <div class="col-3">
            <input id="text2" v-model="text2" v-on:keyup="calcular()" />
          </div>
        </div>
        <div class="col-12">
          <div class="col-3">Resultado :</div>
          <div class="col-12">
            Step : {{ result.steps }} Relative : {{ result.relative }} Similarity
            {{ result.similarity }}
          </div>
        </div>

        <div class="col-12 mt-2">
          <div class="col-3">Aprobacion</div>
          <div class="col-12">
            <div>{{ this.result.similarity >= 0.8 ? " Si" : "No" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as levenshtein from "damerau-levenshtein";

export default {
  data() {
    return {
      text1: "",
      text2: "",
      result: { steps: 0, relative: 0, similarity: 0 },
    };
  },
  mounted() {},
  methods: {
    calcular() {
      //Data
      const text1 = this.clearText(this.text1, "!1");
      const text2 = this.clearText(this.text2, "!2");

      this.comparateNames(text1, text2);
    },

    comparateNames(dataName, scanName) {
      const dataLength = dataName.split(" ").length;
      const scanLength = scanName.split(" ").length;

      let dataText = "?";
      let scanText = "!";

      if (dataLength === 1 && scanLength !== 1) {
        dataText = dataName.trim();
        scanText = scanName.split(" ")[0].trim();
      } else {
        dataText = dataName.trim();
        scanText = scanName.trim();
      }
      const lev = levenshtein(dataText, scanText);

      this.result = lev;

      return lev.similarity > 0.8;
    },
    clearText(text, _default) {
      if (text !== undefined && text !== "") {
        return text.trim().toUpperCase().replaceAll("-", "");
      } else {
        return _default;
      }
    },
  },
  computed: {},
};
</script>
