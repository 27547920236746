<template>
  <div class="container-fluid header-bg">
    <header>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 navbar-brand">
            <img src="/images/logo.svg" />
            <h1>{{ title }}</h1>
          </div>
          <div class="col-12 col-md-6 header-text">
            <p>¿Necesitas ayuda?</p>
            <div>
              <a href="https://wa.me/+50766012772" target="_blank">
                <div><i class="WhatsApp"></i>6601-2772</div>
              </a>
              <a href="tel:8002772" target="_blank">
                <div><i class="Phone"></i>800-2772</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/login.html");
      });
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    username() {
      return this.$store.state.auth.user.username;
    },

    title() {
      return this.$store.state.app.title;
    },
    logo() {
      return this.$store.state.app.logo;
    },
    index() {
      return this.$store.state.wizard.form.profesion;
    },
  },
};
</script>
