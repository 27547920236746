<template>
  <div>
    <b-form @submit.stop.prevent="handleSubmit()">
      <div class="container wizard">
        <div class="row">
          <div class="col-12 p-0">
            <h2>Datos personales</h2>
          </div>
        </div>
        <div class="row paginator">
          <div class="col-md-3 col-3">
            <router-link to="/paso-1.html" class="selected">1<i class="paso-1"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-2.html" class="">2<i class="paso-2"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-3.html" class="">3<i class="paso-3"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-4.html" class="">4<i class="paso-4"></i></router-link>
          </div>
        </div>

        <div class="row content">
          <!-- FORM -->
          <div class="col-12 personal-data-form">
            <!-- NOMBRE -->
            <b-form-group id="profesion-input-group" label-for="firstName" label="Nombre">
              <b-form-input
                id="firstName"
                type="text"
                v-model="form.firstName"
                class="form-control"
                aria-describedby="firstName-live-feedback"
                disabled
              ></b-form-input>
            </b-form-group>
            <!-- APELLIDO -->
            <b-form-group id="lastName-input-group" label-for="lastName" label="Apellido">
              <b-form-input
                id="lastName"
                type="text"
                v-model="form.lastName"
                class="form-control"
                aria-describedby="lastName-live-feedback"
                disabled
              ></b-form-input>
            </b-form-group>
            <!-- TELEFONO -->
            <b-form-group id="phoneNumber-input-group" label-for="phoneNumber" label="Teléfono">
              <b-form-input
                id="phoneNumber"
                v-model="form.phoneNumber"
                class="form-control"
                aria-describedby="phoneNumber-live-feedback"
                disabled
              />
            </b-form-group>
            <!-- EMAIL -->
            <b-form-group id="email-input-group" label-for="email" label="Correo electrónico">
              <b-form-input
                id="email"
                v-model="form.email"
                class="form-control"
                aria-describedby="email-live-feedback"
                disabled
              />
            </b-form-group>

            <!-- DOCUMENTO -->
            <b-form-group
              id="email-input-group"
              label-for="documentNumber"
              label="Cédula de identidad personal"
            >
              <b-form-input
                id="documentNumber"
                v-model="form.documentNumber"
                class="form-control"
                aria-describedby="documentNumber-live-feedback"
                disabled
              />
            </b-form-group>
          </div>
        </div>

        <div class="col-12 btn-container">
          <b-button @click="cancelSubmit" variant="secondary">Rechazar</b-button>
          <b-button type="submit" variant="primary">Siguiente</b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script type="text/javascript" src="../mobbscan-api/js/jquery-3.2.1.min.js"></script>

<script>
import TokenService from "../services/token.service";

export default {
  components: {},

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        documentNumber: "",
        phoneNumber: "",
        documentType: "",
      },
    };
  },

  mounted() {
    const { formData, token } = this.$store.state.app.token;

    if (token === undefined || token === null || token === "") {
      this.$router.push("/");
    }

    this.form.firstName = formData.firstName;
    this.form.lastName = formData.lastName;
    this.form.email = formData.email;
    this.form.documentNumber = formData.documentNumber;
    this.form.phoneNumber = formData.phoneNumber;

    TokenService.getMobbeel()
      .then((response) => {
        this.$store.dispatch("app/mobbeel", response);
      })
      .catch((e) => {
        console.error(e);
      });
  },
  methods: {
    closePopover() {
      this.$refs.popover.$emit("close");
    },

    handleSubmit() {
      this.$store.dispatch("app/documentType", this.form.documentType);
      this.$router.push(`/paso-2.html`);
    },

    cancelSubmit() {
      this.$router.push("/cancel-1.html");
    },
  },
};
</script>
