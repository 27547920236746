<template>
  <div>
    <b-form @submit.stop.prevent="handleSubmit()">
      <div class="container wizard">
        <div class="row">
          <div class="col-12 p-0">
            <h2>{{ title }}</h2>
          </div>
        </div>

        <div v-if="message">
          <b-alert show variant="info">
            <i class="info-icon"></i>
            <div style="width: 100%">
              <h4 class="alert-heading"></h4>
              <p class="mb-0">{{ message }}</p>
            </div>
          </b-alert>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import TokenService from "../services/token.service";

export default {
  components: {},

  data() {
    return {
      message: undefined,
      title: "Iniciando el proceso",
    };
  },
  mounted() {
    const token = this.$route.path.replace("/", "");
    this.$store.dispatch("app/loading", true);

    TokenService.getData(token === "" ? "SN" : token)
      .then((response) => {
        this.$store.dispatch("app/token", response);
        this.$router.push("/paso-1.html");
        this.$store.dispatch("app/loading", false);
      })
      .catch((e) => {
        const { message } = e.response.data;
        this.$store.dispatch("app/token", "");
        this.$store.dispatch("app/loading", false);
        this.message = message ? message : "";
        this.title = "Proceso finalizado";
      });
  },
  methods: {
    handleSubmit() {
      this.$router.push("/paso-2.html");
    },
  },
};
</script>
