<template>
  <footer>
    <div class="container fluid">
      <div class="row text-center">
        <div class="col-12">
          <img class="img-fluid" src="/images/logo-footer.svg" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>
