<template>
  <!-- WIZARD STEP # 5 -->
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Documento con Firma Digital</h2>
      </div>
    </div>
    <div class="row paginator">
      <div class="col-md-3 col-3">
        <router-link to="/paso-1.html" class="passed">1<i class="paso-1"></i></router-link>
      </div>
      <div class="col-md-3 col-3">
        <router-link to="paso-2.html" class="passed">2<i class="paso-2"></i></router-link>
      </div>
      <div class="col-md-3 col-3">
        <router-link to="paso-3.html" class="passed">3<i class="paso-3"></i></router-link>
      </div>
      <div class="col-md-3 col-3">
        <router-link to="paso-4.html" class="selected">4<i class="paso-3"></i></router-link>
      </div>
    </div>

    <div class="row content">
      <div class="col-12">
        <div v-if="accepted" class="alert alert-success" role="alert">
          <i class="ok-icon"></i>
          Has firmado exitosamente la solicitud. En unos segundos podrás continuar con el pago.
        </div>
        <div v-if="!accepted" class="alert alert-danger" role="alert">
          <i class="alert-icon"></i>
          El contrato no fue firmado. Por favor en caso de que necesite una revisión, contáctese con
          el gestor.
        </div>
      </div>

      <Redirect />
    </div>
    <div class="row content">
      <div class="col-12 spacer"></div>
      <div class="col-12 col-md-12 btn-container"></div>
    </div>
  </div>
</template>

<script>
import Redirect from "../components/layout/Redirect.vue";
export default {
  components: { Redirect },
  mounted() {
    if (screen.width < 600) window.scrollTo(0, 220);
  },
  computed: {
    accepted() {
      return this.$store.state.wizard.accepted;
    },
  },
};
</script>
