const configuration = JSON.parse(localStorage.getItem("app"));

const initialState = configuration
  ? configuration
  : {
      loading: false,
      token: {
        token: "",
        formData: {
          firstName: "",
          lastName: "",
          signedRedirectUrl: "https://assa.com/landing/onboarding/finish/",
        },
      },
      mobbeel: {
        url: "",
        key: "",
        score: 50,
      },
      result: {
        code: "",
        description: "",
        scanId: "",
        type: "",
        scores: 0,
        state: "",
        scanProcessValidationResult: {
          enabledProcess: false,
          scanProcessValidationResult: "",
          scanProcessFailedValidations: null,
        },
      },
    };

export const app = {
  namespaced: true,
  state: initialState,
  actions: {
    token({ commit }, token) {
      commit("setToken", token);
      return Promise.resolve(token);
    },
    documentType({ commit }, documentType) {
      commit("setDocumentType", documentType);
      return Promise.resolve(documentType);
    },
    mobbeel({ commit }, mobbeel) {
      commit("setMobbeel", mobbeel);
      return Promise.resolve(mobbeel);
    },
    result({ commit }, result) {
      commit("setResult", result);
      return Promise.resolve(result);
    },
    loading({ commit }, loading) {
      commit("setLoading", loading);
      return Promise.resolve(loading);
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setDocumentType(state, documentType) {
      state.token.formData.documentType = documentType;
    },
    setMobbeel(state, mobbeel) {
      state.mobbeel = mobbeel;
    },
    setResult(state, result) {
      state.result = result;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
};
