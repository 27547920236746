<template>
  <div>
    <b-form @submit.stop.prevent="handleSubmit()">
      <div class="container wizard">
        <div class="row">
          <div class="col-12 p-0">
            <h2>Datos personales</h2>
          </div>
        </div>
        <div class="row paginator">
          <div class="col-md-3 col-3">
            <router-link to="/paso-1.html" class="selected">1<i class="paso-1"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-2.html" class="">2<i class="paso-2"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-3.html" class="">3<i class="paso-3"></i></router-link>
          </div>
          <div class="col-md-3 col-3">
            <router-link to="paso-4.html" class="">4<i class="paso-4"></i></router-link>
          </div>
        </div>

        <div class="row content">
          <!-- PRIMERA COLUMNA -->
          <div class="col-12 col-md-6">
            <!-- NOMBRE -->
            <b-form-group id="profesion-input-group" label-for="firstName" label="Nombre">
              <b-form-input
                id="firstName"
                type="text"
                v-model="form.firstName"
                class="form-control"
                aria-describedby="firstName-live-feedback"
                disabled
              ></b-form-input>
            </b-form-group>
            <!-- EMAIL -->
            <b-form-group id="email-input-group" label-for="email" label="Correo electrónico">
              <b-form-input
                id="email"
                v-model="form.email"
                class="form-control"
                aria-describedby="email-live-feedback"
                disabled
              />
            </b-form-group>
            <!-- Telefono -->
            <b-form-group id="phoneNumber-input-group" label-for="phoneNumber" label="Teléfono">
              <b-form-input
                id="phoneNumber"
                v-model="form.phoneNumber"
                class="form-control"
                aria-describedby="phoneNumber-live-feedback"
                disabled
              />
            </b-form-group>
          </div>

          <!-- SEGUNDA COLUMNA -->
          <div class="col-12 col-md-6">
            <b-form-group id="lastName-input-group" label-for="lastName" label="Apellido">
              <b-form-input
                id="lastName"
                type="text"
                v-model="form.lastName"
                class="form-control"
                aria-describedby="lastName-live-feedback"
                disabled
              ></b-form-input>
            </b-form-group>
            <!-- Documento -->
            <b-form-group
              id="documento-input-group"
              label-for="documentNumber"
              label="Cédula de identidad personal"
            >
              <b-form-input
                id="documentNumber"
                v-model="form.documentNumber"
                class="form-control"
                aria-describedby="documentNumber-live-feedback"
                disabled
              />
            </b-form-group>
          </div>
          <div class="col-12 col-md-12">
            <b-form-group id="comments-input-group" label-for="comments" label="Comentarios">
              <b-form-textarea
                id="comments"
                v-model="form.comments"
                class="form-control"
                aria-describedby="comments-live-feedback"
                required
              />
            </b-form-group>
          </div>
        </div>

        <div class="col-12 btn-container">
          <b-button type="submit" variant="secondary">Enviar cancelación</b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import FormService from "../services/form.service";

export default {
  components: {},

  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        documentNumber: "",
        phoneNumber: "",
        comments: "",
      },
    };
  },
  mounted() {
    const { formData, token } = this.$store.state.app.token;

    if (token === undefined || token === null || token === "") {
      this.$router.push("/");
    }

    this.form.firstName = formData.firstName;
    this.form.lastName = formData.lastName;
    this.form.email = formData.email;
    this.form.documentNumber = formData.documentNumber;
    this.form.phoneNumber = formData.phoneNumber;
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch("app/loading", true);
      const data = {
        token: this.$store.state.app.token.token,
        comments: this.form.comments,
      };

      FormService.cancel(data).then((response) => {
        console.log("Cancel response : ", response.data);
        this.$store.dispatch("wizard/cancelled", response.data.code === "OK");
        this.$store.dispatch("app/loading", false);

        this.$router.push("/cancel-2.html");
      });
    },
  },
};
</script>
