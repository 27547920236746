import * as Sentry from "@sentry/vue";
import { BootstrapVue, IconsPlugin, OverlayPlugin } from "bootstrap-vue";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Import Bootstrap an BootstrapVue CSS files (order is important)

import "./custom.css";
import "./main.scss";

//import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
// OverlayPlugin
Vue.use(OverlayPlugin);

Vue.config.productionTip = false;
Sentry.init({
  Vue,
  dsn: "https://d6526d43aa9316212ee00c64b130e7ee@o464661.ingest.sentry.io/4505863480410112",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

initVue();

function initVue() {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
}
