<template>
  <!-- WIZARD STEP # 2 -->
  <div class="container wizard">
    <div class="row">
      <div class="col-12 p-0">
        <h2>Verificación de indentidad</h2>
      </div>
    </div>
    <div class="row paginator">
      <div class="col-md-3 col-3">
        <router-link to="/paso-1.html" class="passed">1<i class="paso-1"></i></router-link>
      </div>
      <div class="col-md-3 col-3" @click.shift="onClick">
        <router-link to="paso-2.html" class="selected">2<i class="paso-2"></i></router-link>
      </div>
      <div class="col-md-3 col-3">
        <router-link to="paso-3.html" class="">3<i class="paso-3"></i></router-link>
      </div>
      <div class="col-md-3 col-3">
        <router-link to="paso-4.html" class="">4<i class="paso-4"></i></router-link>
      </div>
    </div>

    <div class="col-12 btn-container">
      <b-alert v-if="this.errorCamera" show variant="danger">{{ this.errorCamera }}</b-alert>

      <b-button v-if="this.errorCamera" type="submit" variant="primary" @click="restartMobbScan">
        <b-icon icon="arrow-counterclockwise" />
      </b-button>
    </div>

    <div class="row content">
      <div id="mobbscan-placeholder"></div>
    </div>

    <div class="col-12 btn-container">
      <b-button
        type="submit"
        variant="primary"
        @click="validateDocumentImages"
        v-if="this.visibleValidateDocument"
        v-bind:disabled="!this.enabledValidateDocument"
      >
        {{ !this.documentValiding ? "continuar" : "Validando" }}
        <b-icon v-if="this.documentValiding" icon="three-dots" animation="cylon"
      /></b-button>
      <b-button
        type="submit"
        variant="primary"
        @click="handleSubmit"
        v-if="this.visibleValidateFace"
        v-bind:disabled="!this.enabledValidateFace"
      >
        Continuar
      </b-button>
    </div>
  </div>
</template>

<script type="text/javascript" src="../mobbscan-api/js/jquery-3.2.1.min.js"></script>
<script type="text/javascript" src="../mobbscan-api/mobbscan-api-2.8.29.min.js"></script>
<script type="text/javascript" src="../mobbscan-api/mobbscan-api-face-2.8.29.min.js"></script>

<script>
import { frontFake, backFake, faceFake } from "../services/image.service";

const scanningMessage = "⚡️ Escaneando...";
const initMessageFront =
  "⤴️ Haga clic en la imagen para iniciar la captura. Coloque el frente de la cédula en el recuadro hasta que el borde cambie a color verde. Luego, mantenga la cédula fija por unos segundos.";

const initMessageBack =
  "Haga clic en la imagen para iniciar la captura. Coloque el dorso de la cédula en el recuadro hasta que el borde cambie a color verde. Luego, mantenga la cédula fija por unos segundos.";

const initMessageFace =
  "Ahora, haga clic en la imagen para iniciar la captura, coloque la cámara frente a su rostro mientras escanea, hasta completar el borde verde.";

const reScanMessage =
  "<p><b>Para volver a tomar la foto, da clic en la imagen, de lo contrario, da clic en “Continuar”</p></b>";
export default {
  mounted() {
    const { token, formData } = this.$store.state.app.token;
    const { mobbeel } = this.$store.state.app;

    if (token === undefined || token === null || token === "") {
      const token2 = localStorage.token;

      if (token2 !== undefined && token2 !== null && token2 !== "") {
        this.$router.push(`/${token2}`);
        return;
      } else {
        this.$router.push("/");
        return;
      }

      localStorage.token = token;

      this.$store.dispatch("wizard/documentFront", undefined);
      this.$store.dispatch("wizard/documentBack", undefined);
      this.$store.dispatch("wizard/face", {
        image: undefined,
        data: {
          state: "NONE",
          scores: {
            faceMatching: 0,
            identityVerification: 0,
            liveness: 0,
          },
        },
      });
    }
    this.initMobbScan(mobbeel);

    if (screen.width < 600) window.scrollTo(0, 220);
  },
  data() {
    return {
      frontImage: undefined,
      backImage: undefined,
      documentValid: false,
      documentValiding: false,
      faceImage: undefined,
      faceValid: false,
      faceValiding: false,
      errorCamera: "",
    };
  },
  components: {},
  methods: {
    restartMobbScan() {
      window.location.reload(true);
    },
    previous() {
      this.$router.push("/paso-1.html");
    },
    next(response) {
      this.$store.dispatch("app/result", response);
      this.$router.push("/paso-3.html");
    },
    b64toBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/png" });
    },
    onClick() {
      if (!this.frontImage) {
        console.log("Set fake document scan");
        this.$store.dispatch("wizard/documentFront", frontFake);
        this.$store.dispatch("wizard/documentBack", backFake);

        this.frontImage = this.b64toBlob(frontFake);
        this.backImage = this.b64toBlob(backFake);
      } else {
        console.log("Set fake face scan");
        this.faceImage = this.b64toBlob(faceFake);
      }
    },
    initMobbScan(mobbeel) {
      // Sets license
      MobbScanAPI.initAPI(mobbeel.key);
      // Sets base url
      MobbScanAPI.setBaseURL(mobbeel.url);
      // Sets placeholder
      MobbScanAPI.setPlaceholder("mobbscan-placeholder");
      // This listener is called when a frame has been analyzed or detection starts
      MobbScanAPI.setDetectionListener(this.onDocumentDetectionResult);
      // This listener is called when a face frame has been analyzed
      MobbScanAPI.setFaceDetectionListener(this.onFaceDetectionResult);
      // If you want to use the native mode on mobile you have to call this method
      MobbScanAPI.enableVideoMobileCapture();
      // Sets the timeoutListener
      MobbScanAPI.setTimeOutListener(console.error);
      // Sets the timeoutListener on mobile
      MobbScanAPI.setTimeOutListenerVideoMobile(console.error);
      // Sets the error accessing camera
      MobbScanAPI.setErrorAccessingCamera(this.onErrorAccessingCamera);
      // Sets Eso lo que hace es quitar validaciones al dorso...
      MobbScanAPI.setFaceDetectionEnabled(false);
      // Set enableLivenessPassive
      MobbScanAPI.enableLivenessPassive();

      // Starts scanning
      MobbScanAPI.start(
        "PANIDCard",
        MobbScanOperationMode.SCAN_BOTH_SIDES,
        (result, resultData, error) => {
          console.log("API has started", resultData, error);
        }
      );
      this.setFrontSubtitle(initMessageFront);
      this.setBackSubtitle(initMessageBack);
      this.setFaceSubtitle(initMessageFace);

      $("#msw-image-FRONT").attr("src", "/images/dni-nuevo-front.jpg");
      $("#msw-image-BACK").attr("src", "/images/dni-nuevo-back.jpg");
    },
    onErrorAccessingCamera(error) {
      console.log(error);
      if (error.code == "WEBCAM_ERROR") {
        this.errorCamera = "Se produjo un error en la camara, no se puede avanzar";
      }

      if (error.code == "WEBCAM_NO_DEVICES_FOUND") {
        this.errorCamera = "No se encontro un dispositivo de camara, no se puede avanzar";
      }

      if (error.code == "WEBCAM_NOT_AVAILABLE") {
        this.errorCamera = "Camara no disponible, no se puede avanzar";
      }

      if (error.code == "WEBCAM_NOT_SUPPORTED_ERROR") {
        this.errorCamera = "Camara no soportada, no se puede avanzar";
      }

      if (error.code == "WEBCAM_PERMISSION_DENIED") {
        this.errorCamera =
          "Debe dar permiso al navegador para utilizar la camara y reactualice la operación";
      }
    },
    onDocumentDetectionResult(result, data, error) {
      let subTitleText;
      const { side } = data;
      var $this = this;

      if (error) {
        console.error(error);
      }

      if (side !== MobbScanDocumentSide.FRONT && side !== MobbScanDocumentSide.BACK) {
        return;
      }

      if (result === MobbScanDetectionResult.TIMEOUT) {
        subTitleText =
          data.side === MobbScanDocumentSide.FRONT ? initMessageFront : initMessageBack;
      } else {
        subTitleText = scanningMessage;
      }

      if (
        result === MobbScanDetectionResult.CAPTURING ||
        result === MobbScanDetectionResult.NOT_DETECTED
      ) {
        this.drawDetectionBorder(result, data.side);
      }

      // UI updates based on the data received
      if (data && data.side === MobbScanDocumentSide.FRONT) {
        this.setFrontSubtitle(subTitleText);
      } else if (data && data.side === MobbScanDocumentSide.BACK) {
        this.setBackSubtitle(subTitleText);
      }

      // If the result is "DETECTED" whe should inspect on which side of the document.
      if (result === MobbScanDetectionResult.DETECTED) {
        this.documentValid = false;
        const detectedSide = data.side;
        const image = data.image;

        this.drawDetectionBorder(result, detectedSide);

        if (detectedSide === MobbScanDocumentSide.FRONT) {
          this.setFrontSubtitle("Frente del documento detectado" + reScanMessage);
          this.frontImage = image;

          var reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onloadend = function () {
            $this.$store.dispatch("wizard/documentFront", reader.result);
          };
        } else if (data.side === MobbScanDocumentSide.BACK) {
          this.setBackSubtitle("Dorso del documento detectado" + reScanMessage);
          this.backImage = image;

          var reader = new FileReader();
          reader.readAsDataURL(image);

          reader.onloadend = function () {
            $this.$store.dispatch("wizard/documentBack", reader.result);
          };
        }
      }
    },
    onFaceDetectionResult(result, data, error) {
      let subTitleText;

      if (error) {
        console.error(error);
      }

      if (result === MobbScanFaceDetectionResult.STARTED) {
        this.faceValid = false;
        this.faceValiding = false;
        this.faceImage = undefined;
      }

      if (
        result === MobbScanFaceDetectionResult.TIMEOUT ||
        result === MobbScanFaceDetectionResult.PROCESS_CANCELLED
      ) {
        subTitleText = initMessageFace;
      } else {
        subTitleText = scanningMessage;
      }

      this.setFaceSubtitle(subTitleText);

      // If a DETECTED result is received from a WEBCAM...
      if (result === MobbScanFaceDetectionResult.DETECTED) {
        console.log("Resultado MobbScanFaceDetectionResult.DETECTED", data);

        this.setFaceSubtitle(reScanMessage);

        this.faceValid = data.validations.liveness === "VALID";

        // data.validations.faceMatching === "VALID" &&

        /*
        if (data.validations.faceMatching !== "VALID") {
          this.setFaceSubtitle("Lo sentimos, El rostro no es coincidente con el documento");
          this.faceImage = undefined;
          this.faceValid = false;
          this.faceValiding = false;
          return;
        }
        */

        if (data.validations.liveness !== "VALID") {
          this.setFaceSubtitle("Lo sentimos, La prueba de vida no resulto satisfactoria");
          this.faceImage = undefined;
          this.faceValid = false;
          this.faceValiding = false;
          return;
        }

        this.$store.dispatch("wizard/face", {
          image: "data:image/jpeg;base64," + data.image,
          data: { state: "NONE", scores: data.scores, scanId: data.scanId },
        });

        this.faceValid = true;
        this.faceValiding = false;
        this.faceImage = data.image;
      }
    },

    validateDocumentImages() {
      const startFaceScanningStep = () => {
        $("#msw-div-documents").hide();
        $("#msw-div-face").show();
        $("#face-content").show();
      };

      var $this = this;

      this.documentValiding = true;

      this.setBothSidesSubtitle("Espere por favor mientras los documentos son validados...");
      // When boths sides are ready, call `scan` with both detected images so the server
      // checks that bots sides match
      MobbScanAPI.scan(this.frontImage, this.backImage, (result, data, error) => {
        // Callback called when `scan` request finishes

        if (result === MobbScanScanResult.ERROR) {
          $this.documentValiding = false;
          $this.setBothSidesSubtitle(
            "<div class='erroMessage'>El documento no pudo ser validado</div>" + reScanMessage
          );
        }
        if (result === MobbScanScanResult.OK) {
          console.log(result);
          $this.$store.dispatch("wizard/documentData", data);
          $this.documentValiding = false;
          $this.documentValid = true;
          $this.setBothSidesSubtitle("El documento fue validado correctamente");
          // If everything matches, show the face detection elements
          // (setTimeout for visualization purposes)
          setTimeout(startFaceScanningStep, 3000);
        }
      });
    },
    drawDetectionBorder(result, side) {
      var detectionElement = document.getElementById("div-video-" + side);
      var mobileDetectionElement = document.getElementById("div-video-fake-" + side);

      switch (result) {
        case MobbScanDetectionResult.CAPTURING:
          if (detectionElement) {
            // for desktop
            detectionElement.classList.add("div-video-detected");
          } else if (mobileDetectionElement) {
            // for tablet and mobile devices
            mobileDetectionElement.classList.add("div-video-mobile-detected");
          }
          break;
        default:
          if (detectionElement) {
            // for desktop
            detectionElement.classList.remove("div-video-detected");
          } else if (mobileDetectionElement) {
            // for tablet and mobile devices
            mobileDetectionElement.classList.remove("div-video-mobile-detected");
          }
          break;
      }
    },

    handleSubmit() {
      this.next();
    },

    setFrontSubtitle(text) {
      MobbScanAPI.setSubtitle(MobbScanDocumentSide.FRONT, text);
    },
    setBackSubtitle(text) {
      MobbScanAPI.setSubtitle(MobbScanDocumentSide.BACK, text);
    },
    setFaceSubtitle(text) {
      MobbScanAPI.setSubtitle("FACE", text);
    },
    setBothSidesSubtitle(text) {
      this.setFrontSubtitle(text);
      this.setBackSubtitle(text);
    },
  },

  computed: {
    visibleValidateDocument() {
      return !this.documentValid && !!this.frontImage && !!this.backImage;
    },
    enabledValidateDocument() {
      return !this.documentValiding;
    },
    visibleValidateFace() {
      return this.faceValid && !!this.faceImage;
    },
    enabledValidateFace() {
      return !this.faceValiding;
    },
  },
};
</script>

<style>
.msw-title {
  display: none;
}

.div-video::after {
  border-width: 3px;
  border-style: solid;
  border-color: grey;
}

.div-video-mobile::after {
  border-width: 3px;
  border-style: solid;
  border-color: grey;
}

.div-video-detected::after {
  border-color: greenyellow;
}

.div-video-mobile-detected::after {
  border-color: greenyellow;
}
</style>
